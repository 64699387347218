import {createApp} from 'vue'
import App from './App.vue';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import store from './store';
import router from './router';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

// fontawesome
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faPlus,
    faCheckCircle,
    faEdit,
    faTrash,
    faCat,
    faSyncAlt,
    faSort,
    faSortUp,
    faSortDown,
    faTh,
    faList,
    faWindowClose,
    faCamera,
    faStop,
    faPen,
    faCheck,
    faTimes,
    faSave,
    faEye,
    faComment,
    faEnvelope,
    faUser,
    faComments,
    faArchive,
    faMinus,
    faHourglass,
    faExclamation,
    faClipboard,
    faTasks,
    faAngleRight,
    faAngleDown,
    faTruck,
    faObjectGroup
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

library.add(faPlus, faCheckCircle, faEdit, faTrash, faCat, faSyncAlt, faSort, faSortUp, faSortDown, faTh, faList,
    faWindowClose, faCamera, faStop, faPen, faCheck, faTimes, faSave, faEye, faComment, faUser, faComments, faEnvelope,
    faArchive, faMinus, faExclamation, faHourglass, faClipboard, faTasks, faAngleDown, faAngleRight, faTruck, faObjectGroup);


const app = createApp(App).use(store).use(router);

app.component(VueQrcode.name, VueQrcode);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app')