<template>
    <div class="container-fluid px-xl-5 mt-3">
        <div class="row">
            <div class="col-xl-8 offset-xl-2">
                <div class="card bg-dark text-light mb-2" id="filters">
                    <div class="card-header">
                        <ul class="nav nav-tabs card-header-tabs">
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{name: 'admin'}" active-class="dummy" exact-active-class="active">Dashboard</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{name: 'settings'}" active-class="active">Settings</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{name: 'events'}" active-class="active">Events</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{name: 'notifications'}" active-class="active">Notifications</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{name: 'shipping'}" active-class="active">Shipping</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{name: 'admin_boxes'}" active-class="active">Boxes</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" :to="{name: 'users'}" active-class="active">Access Control</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Cards from "@/components/Cards.vue";

export default {
    name: 'Admin',
    components: {Cards},
    computed: {
        ...mapGetters(['getEventSlug']),
    },
};
</script>

<style scoped>

</style>