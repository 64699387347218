<template>
    <table class="table table-striped table-dark">
        <thead>
        <tr>
            <th scope="col" v-for="(column, index) in columns" :key="index"
                v-if="columnHasData[index]||columnHasSlot[index]">
                <div class="input-group" v-if="columnHasData[index]">
                    <div class="input-group-prepend">
                        <button
                            :class="[ 'btn', column === sortBy ? 'btn-outline-info' : 'btn-outline-secondary' ]"
                            @click="toggleSort(column)"
                        >
                            {{ column }}
                            <span :class="{ 'text-info': column === sortBy }">
                                    <font-awesome-icon :icon="getSortIcon(column)"/>
                                </span>
                        </button>
                    </div>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="filter"
                        :value="filters[column]"
                        @input="changeFilter(column, $event.target.value)"
                    >
                </div>
                <span v-else-if="columnHasSlot[index]">
                    {{ column }}
                </span>
            </th>
            <th>
                <slot name="header_actions"/>
            </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in internalItems" :key="item[keyName]" @click="$emit('itemActivated', item)">
            <td v-for="(column, index) in columns" :key="index" v-if="columnHasSlot[index]||columnHasData[index]">
                <slot v-if="columnHasSlot[index]" :name="column" :item="item"/>
                <span v-else-if="columnHasData[index]">
                    {{ item[column] }}
                </span>
                <span v-else>
                    {{ column }}
                </span>
            </td>
            <td>
                <slot v-bind:item="item" name="actions"/>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import DataContainer from '@/mixins/data-container';
import router from '../router';

export default {
    name: 'SlotTable',
    mixins: [DataContainer],
    data() {
        return {
            columnHasSlot: [],
            columnHasData: []
        }
    },
    created() {
        this.columns.map(e => ({
            k: e,
            v: this.$store.getters.getFilters[e]
        })).filter(e => e.v).forEach(e => this.setFilter(e.k, e.v));

    },
    mounted() {
        this.columnHasSlot = this.columns.map(e => Object.keys(this.$slots).includes(e));
        this.columnHasData = this.columns.map(e => this.items.reduce((a, b) => a || b[e] !== undefined, false));
        //console.log(this.columnHasData, this.columnHasSlot, this.columns, Object.keys(this.$slots), this.$slots);
        for (let slot in this.$slots) {
            console.log(`Slot: ${slot}`);
            console.log(`Data: ${this.$slots[slot]}`);
        }
    },
    beforeUpdate() {
        this.columnHasSlot = this.columns.map(e => Object.keys(this.$slots).includes(e));
        this.columnHasData = this.columns.map(e => this.items.reduce((a, b) => a || b[e] !== undefined, false));
    },
    methods: {
        changeFilter(col, val) {
            this.setFilter(col, val);
            let newquery = Object.entries({
                ...this.$store.getters.getFilters,
                [col]: val
            }).reduce((a, [k, v]) => (v ? {...a, [k]: v} : a), {});
            router.push({query: newquery});
        },
    },
};
</script>

<style>
.table-body-move {
    transition: transform 1s;
}
</style>