<template>
    <div>
        <Table :items="userNotificationChannels.map(channel => ({...channel, username: channel.user.username || {}}))"
               :columns="['id', 'username', 'channel_type', 'channel_target', 'event_filter', /*'active', 'created'*/]">
            <template #actions="{ item }">
                <div class="btn-group">
                    <button class="btn btn-danger" @click.stop="">
                        <font-awesome-icon icon="trash"/>
                        delete
                    </button>
                </div>
            </template>
        </Table>
        <div class="card bg-dark">
            <div class="card-body">
                <div class="input-group">
                    <select class="form-control">
                        <option value="1">user</option>
                        <option value="2">admin</option>
                    </select>
                    <select class="form-control">
                        <option value="email">Email</option>
                        <option value="telegram">Telegram</option>
                    </select>
                    <input type="text" class="form-control" placeholder="channel_target">
                    <input type="text" class="form-control" value="*">
                    <div class="input-group-append">
                        <button class="btn btn-primary">Add</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import Table from '@/components/Table';

export default {
    name: 'Notifications',
    components: {Table},
    computed: mapState(['userNotificationChannels']),
    methods: mapActions(['fetchUserNotificationChannels']),
    mounted() {
        this.fetchUserNotificationChannels();
    }
};
</script>

<style scoped>

</style>