<template>
    <AsyncLoader :loaded="ticket.id">
        <div class="container-fluid px-xl-5 mt-3">
            <div class="row">
                <div class="col-xl-8 offset-xl-2">
                    <div class="card bg-dark text-light mb-2" id="filters">
                        <div class="card-header">
                            <h3>Ticket #{{ ticket.id }} - {{ ticket.name }}</h3>
                        </div>
                        <Timeline :timeline="ticket.timeline" @sendMail="handleMail" @addComment="handleComment"/>
                        <div class="card-footer d-flex justify-content-between">
                            <button class="btn btn-secondary mr-2" @click="$router.go(-1)">Back</button>
                            <!--button class="btn btn-danger" @click="deleteItem({type: 'tickets', id: ticket.id})">
                                <font-awesome-icon icon="trash"/>
                                Delete
                            </button-->
                            <div class="btn-group">
                                <select class="form-control" v-model="ticket.assigned_to">
                                    <option v-for="user in users" :value="user.username">{{ user.username }}</option>
                                </select>
                                <button class="form-control btn btn-success" @click="assignTicket(ticket)">
                                    Assign&nbsp;Ticket
                                </button>
                            </div>
                            <div class="btn-group">
                                <select class="form-control" v-model="ticket.state">
                                    <option v-for="status in state_options" :value="status.value">{{
                                            status.text
                                        }}
                                    </option>
                                </select>
                                <button class="form-control btn btn-success" @click="changeTicketStatus(ticket)">
                                    Change&nbsp;Status
                                </button>
                            </div>
                        </div>
                        <div class="card-footer d-flex justify-content-between">
                            <ClipboardButton :payload="shippingEmail" class="btn btn-primary">
                                <font-awesome-icon icon="clipboard"/>
                                Copy&nbsp;DHL&nbsp;contact&nbsp;to&nbsp;clipboard
                            </ClipboardButton>
                            <div class="btn-group">
                                <select class="form-control" v-model="shipping_voucher_type">
                                    <option v-for="type in availableShippingVoucherTypes.filter(t=>t.count>0)"
                                            :value="type.id">{{ type.name }}
                                    </option>
                                </select>
                                <button class="form-control btn btn-success"
                                        @click="claimShippingVoucher({ticket: ticket.id, shipping_voucher_type}).then(()=>shipping_voucher_type=null)"
                                        :disabled="!shipping_voucher_type">
                                    Claim&nbsp;Shipping&nbsp;Voucher
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AsyncLoader>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import Timeline from "@/components/Timeline.vue";
import ClipboardButton from "@/components/inputs/ClipboardButton.vue";
import AsyncLoader from "@/components/AsyncLoader.vue";

export default {
    name: 'Ticket',
    components: {AsyncLoader, ClipboardButton, Timeline},
    data() {
        return {
            shipping_voucher_type: null
        }
    },
    computed: {
        ...mapState(['tickets', 'state_options', 'users']),
        ...mapGetters(['availableShippingVoucherTypes']),
        ticket() {
            const id = parseInt(this.$route.params.id)
            const ret = this.tickets.find(ticket => ticket.id === id);
            return ret ? ret : {};
        },
        shippingEmail() {
            const domain = document.location.hostname;
            return `ticket+${this.ticket.uuid}@${domain}`;
        }
    },
    methods: {
        ...mapActions(['deleteItem', 'markItemReturned', 'sendMail', 'updateTicketPartial', 'postComment']),
        ...mapActions(['loadTickets', 'fetchTicketStates', 'loadUsers', 'scheduleAfterInit']),
        ...mapActions(['claimShippingVoucher', 'fetchShippingVouchers']),
        handleMail(mail) {
            this.sendMail({
                id: this.ticket.id,
                message: mail
            })
        },
        handleComment(comment) {
            this.postComment({
                id: this.ticket.id,
                message: comment
            })
        },
        changeTicketStatus(ticket) {
            this.updateTicketPartial({
                id: ticket.id,
                state: ticket.state
            })
        },
        assignTicket(ticket) {
            this.updateTicketPartial({
                id: ticket.id,
                assigned_to: ticket.assigned_to
            })
        },
    },
    mounted() {
        this.scheduleAfterInit(() => [this.fetchTicketStates(), this.loadTickets(), this.loadUsers(),
            this.fetchShippingVouchers()]);
    }
};
</script>

<style scoped>

</style>