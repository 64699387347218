<template>
    <h3 class="text-center">Available Message Template Variables</h3>
    <p>
        <span v-for="(variable, key) in messageTemplateVariables" :key="key" class="badge badge-primary"
              style="margin: 5px;">
            {{ variable }}
        </span>
    </p>
    <h3 class="text-center">Message Templates</h3>
    <div v-for="template in messageTemplatesIntermediate" :key="template.id" class="card bg-dark"
         style="margin-bottom: 10px;">
        <div class="card-header">{{ template.name }}</div>
        <FormatedText :value="template.message" :format="formatText" class="card-body"
                      @input="changeMessageTemplate(template.id, $event)"/>
        <div class="card-body">
            <button class="btn btn-primary" @click="resetMessageTemplate(template.id)"
                    :disabled="messageTemplates.find(t => t.id === template.id).message === template.message">Reset
            </button>
            <button class="btn btn-success" @click="saveMessageTemplate(template.id)"
                    :disabled="messageTemplates.find(t => t.id === template.id).message === template.message">Save
            </button>
        </div>
    </div>
    <div class="card bg-dark">
        <div class="card-body">
            <div class="input-group">
                <input type="text" class="form-control" v-model="newTemplateName" placeholder="New Template Name">
                <button class="btn btn-success input-group-btn" @click="createMessageTemplateAndReset()"
                        ref="createButton">Create
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import Table from '@/components/Table';
import FormatedText from "@/components/inputs/FormatedText.vue";

export default {
    name: 'Settings',
    components: {FormatedText, Table},
    data() {
        return {
            messageTemplatesIntermediate: [],
            newTemplateName: '',
        };
    },
    computed: mapState(['messageTemplates', 'messageTemplateVariables']),
    methods: {
        ...mapActions(['fetchMessageTemplates', 'fetchMessageTemplateVariables', 'updateMessageTemplate', 'createMessageTemplate']),
        formatText(value) {
            return value.replace(/{{(.+?)}}/g, (match, key) => {
                return `<span class="text-primary">{{${key}}}</span>`;
            }).replace(/\n/g, '<br>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
        },
        changeMessageTemplate(id, message) {
            console.log(id, message);
            this.messageTemplatesIntermediate.forEach(template => {
                if (template.id === id) {
                    template.message = message;
                }
            });
        },
        saveMessageTemplate(id) {
            this.updateMessageTemplate(this.messageTemplatesIntermediate.find(template => template.id === id));
        },
        resetMessageTemplate(id) {
            this.messageTemplatesIntermediate.find(template => template.id === id).message =
                this.messageTemplates.find(template => template.id === id).message;
        },
        async createMessageTemplateAndReset() {
            this.$refs.createButton.disabled = true;
            await this.createMessageTemplate(this.newTemplateName);
            this.newTemplateName = '';
            this.$refs.createButton.disabled = false;
        },
    },
    mounted() {
        this.fetchMessageTemplates().then(() => {
            this.messageTemplatesIntermediate = JSON.parse(JSON.stringify(this.messageTemplates));
        });
        this.fetchMessageTemplateVariables();
    },
    watch: {
        messageTemplates() {
            for (const template of this.messageTemplates) {
                if (!this.messageTemplatesIntermediate.find(t => t.id === template.id)) {
                    this.messageTemplatesIntermediate.push(JSON.parse(JSON.stringify(template)));
                }
            }
            for (const template of this.messageTemplatesIntermediate) {
                if (!this.messageTemplates.find(t => t.id === template.id)) {
                    this.messageTemplatesIntermediate = this.messageTemplatesIntermediate.filter(t => t.id !== template.id);
                }
            }
        }
    }
};
</script>

<style scoped>
pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    color: inherit;
}
</style>