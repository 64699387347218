<template>
    <div>
        <div>
            <ul>
                <li>
                    <button class="btn btn-primary" @click="addTest('test')">+</button>
                </li>
                <li v-for="(t, index) in test" :key="index">
                    {{ t }}
                    <button class="btn btn-link" @click="removeTest(index)">-</button>
                </li>
            </ul>
        </div>
        <vue-qrcode :value="qr_url" tag="svg" :size="200" :options="{errorCorrectionLevel: 'H'}"></vue-qrcode>
        <!--qr-code :text="" color="#000" bg-color="#fff" error-level="H" ></qr-code-->
        <h3 class="text-center">Events</h3>
        <!--p>{{ events }}</p-->
        <span>{{ events.length }} loaded events</span>
        <ul class="hidden">
            <li v-for="event in events" :key="event.id">
                {{ event.slug }}
            </li>
        </ul>
        <h3 class="text-center">Items</h3>
        <!--p>{{ loadedItems }}</p-->
        <span>{{ loadedItems.length }} loaded items</span>
        <ul class="hidden">
            <li v-for="item in loadedItems" :key="item.id">
                {{ item.description }}
            </li>
        </ul>
        <h3 class="text-center">Boxes</h3>
        <!--p>{{ loadedBoxes }}</p-->
        <span>{{ loadedBoxes.length }} loaded boxes</span>
        <ul class="hidden">
            <li v-for="box in loadedBoxes" :key="box.id">
                {{ box.name }}
            </li>
        </ul>
        <h3 class="text-center">Issues</h3>
        <!--p>{{ issues }}</p-->
        <ul>
            <li v-for="issue in tickets" :key="issue.id">
                {{ issue.id }}
            </li>
        </ul>
    </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import Table from '@/components/Table';

export default {
    name: 'Debug',
    components: {Table},
    computed: {
        ...mapState(['events', 'loadedItems', 'loadedBoxes', 'tickets']),
        ...mapState(['events', 'loadedItems', 'loadedBoxes']),//, 'mails', 'issues', 'systemEvents']),
        ...mapState(['test']),
        qr_url() {
            return window.location.href;
        }
    },
    methods: {
        ...mapActions(['changeEvent', 'loadTickets']),

        ...mapActions(['changeEvent']),//, 'loadMails', 'loadIssues', 'loadSystemEvents']),
        ...mapMutations(['setTest']),
        addTest(test) {
            const tests = [...this.test, test];
            this.setTest(tests);
        },
        removeTest(index) {
            const tests = [...this.test];
            tests.splice(index, 1);
            this.setTest(tests);
        }
    },
    mounted() {
        this.loadTickets();
    }
};
</script>

<style>
</style>